.ql-tooltip {
    z-index: 1000 !important;
    min-width: 300px !important;
}
.ql-picker-options {
    z-index: 1000 !important;
}

.ql-picker-label[data-value="8pt"]::before,
.ql-picker-item[data-value="8pt"]::before {
    content: "Tiny" !important;
}
.ql-picker-label[data-value="10pt"]::before,
.ql-picker-item[data-value="10pt"]::before {
    content: "Very Small" !important;
}
.ql-picker-label[data-value="12pt"]::before,
.ql-picker-item[data-value="12pt"]::before {
    content: "Small" !important;
}
.ql-picker-label[data-value="16pt"]::before,
.ql-picker-item[data-value="16pt"]::before {
    content: "Normal" !important;
}
.ql-picker-label[data-value="20pt"]::before,
.ql-picker-item[data-value="20pt"]::before {
    content: "Large" !important;
}
.ql-picker-label[data-value="24pt"]::before,
.ql-picker-item[data-value="24pt"]::before {
    content: "Very Large" !important;
}

.PhoneInputInput {
    border: 0;
    border-bottom: 1px solid #cecece;
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    padding: 5px;
}
